import React, { useEffect, useRef } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const defaultCenter = {
  lat: 31.569669,
  lng: 34.670943
};

const locations = [
  { lat: 31.674891, lng: 34.575039 },
  { lat: 31.614630, lng: 34.767731 },
  { lat: 31.522694, lng: 34.595581 },
  { lat: 31.804380, lng: 34.655315 },
  { lat: 31.3067921, lng: 34.61821999590485 },
  { lat: 31.2457442, lng: 34.7925181 },
  { lat: 31.4213546, lng: 34.5884252 },
  { lat: 31.8786995, lng: 34.73663828016258 },
  { lat: 31.78788255, lng: 34.71223535811363 },
  { lat: 31.97043395, lng: 34.79216977610769 }
];

// Define the libraries array outside the component
const libraries = ['marker'];

const MapComponent = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCHLNqsDVN9M4tAfhSpXlBCiBOvq8eNuaE',
    language: 'he',
    libraries
  });

  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: defaultCenter,
        zoom: 9.5,
        mapId: '4bed956c50bd88b2'  // Use your Map ID here
      });

      locations.forEach(location => {
        new window.google.maps.marker.AdvancedMarkerElement({
          position: location,
          map: map,
          title: 'Marker'
        });
      });
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={mapRef} style={containerStyle}></div>
  );
};

export default MapComponent;
