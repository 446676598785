import React from "react";

function Select({ name, inputData, label, dataRef, handleChange }) {

  return (
    <>
      <label className="label_style" htmlFor={name}>{label}</label>
      <select className="input_area_select" id={name} name={name} value={dataRef} onChange={handleChange}>
        {inputData.map((data) => (
          <option  key={data} value={data}>
            {data}
          </option>
        ))}
      </select>
    </>
  );
}

export default Select;