import React, { useContext } from "react";
import "../navigationHeader/navigationHeader.css";
import logo from "../../assets/logos/logo-arnold-M.png";
import { ScrollContext } from "../../util/ScrollContext";

const NavigationHeaderPC = () => {
  const { scrollToSection } = useContext(ScrollContext);
  return (
    <header className="sticky-header">
      <nav>
        <ul>
          <div className="contact_number_container">
          </div>
          <div className="navigation_list">
            <li onClick={() => scrollToSection("jobsNavigator")}>סוגי עבודות</li>
            <li onClick={() => scrollToSection("businessDescription")}>אודות</li>
            <li onClick={() => scrollToSection("contactForm")}>צרו קשר</li>
          </div>
        </ul>
      </nav>
      <div className="logo-container">
        <img src={logo} alt="Logo" />
      </div>
    </header>
  );
};

export default NavigationHeaderPC;
