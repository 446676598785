import React, { useLayoutEffect, useState } from 'react'
import NavigationHeaderPC from './NavigationHeaderPC';
import NavigationHeaderMobile from './NavigationHeaderMobile';

const NavigationHeader = () => {

    const [isMobile, setIsMobile] = useState(false);
    useLayoutEffect(() => {
      if (window.innerWidth < 600) {
        setIsMobile(true);
      }
    }, [isMobile]);

    if(isMobile){
        return <NavigationHeaderMobile/>
      }
    
       return <NavigationHeaderPC/>
    }


export default NavigationHeader