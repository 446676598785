import React, { useEffect, useState } from 'react';
import "../sticky_footer/stickeyFooter.css";
import { FaWhatsapp } from "react-icons/fa";
import { IoCall } from "react-icons/io5";

const StickyFooter = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  const phoneNumber = "+972549116717"


    const handleCall = () => {
      window.location.href = `tel:${phoneNumber}`;
    };

    const handleWA =()=>{
        const url = "https://wa.me/"+phoneNumber
        window.open(url,"black").focus();
    }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <footer className={`sticky-footer ${isVisible ? 'visible' : 'hidden'}`}>
      <div className='sticky_container'>
        <div onClick={()=>handleWA()} className='wa_icon'><FaWhatsapp size={55}/></div>
        <div onClick={()=>handleCall()} className='call_section'>054-911-6717 <IoCall size={40}/></div>
      </div>
    </footer>
  );
};

export default StickyFooter;
