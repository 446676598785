import React, { useState } from 'react';
import './slick_image_slider.css';  // New CSS file name

const SlickImageSlider = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const length = images.length;
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStartX || !touchEndX) {
      return;
    }
    const diff = touchStartX - touchEndX;

    if (diff > 50) {
      nextSlide();
    }

    if (diff < -50) {
      prevSlide();
    }

    setTouchStartX(null);
    setTouchEndX(null);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  return (
    <div
      className='custom-slider'
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <span className='custom-left-arrow' onClick={prevSlide}>&#10094;</span>
      <span className='custom-right-arrow' onClick={nextSlide}>&#10095;</span>
      {images.map((image, index) => (
        <div className={index === current ? 'custom-slide custom-active' : 'custom-slide'} key={index}>
          {index === current && (
            <img src={image} alt='slide' className='custom-image' />
          )}
        </div>
      ))}
      <div className="custom-dots">
        {images.map((_, idx) => (
          <span key={idx} className={idx === current ? "custom-dot custom-active-dot" : "custom-dot"} onClick={() => setCurrent(idx)}></span>
        ))}
      </div>
    </div>
  );
};

export default SlickImageSlider;
