import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../wa_contact_button/wa_contact_button.css";
import WaPopUp from "./WaPopUp";
import WaContactForm from "./WaContactForm";

const WaContactButton = ({ jobDetails }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };


  return (
    <>
      <div onClick={openPopup} className="wa_contact_container">
        <FaWhatsapp size={40} color="white" />
        <span className="button_text">הזמינו את השירות הזה ב-</span>
      </div>
      <WaPopUp isOpen={isPopupOpen} onClose={closePopup}>
        <WaContactForm jobs={jobDetails}/>
      </WaPopUp>
    </>
  );
};

export default WaContactButton;
