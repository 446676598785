import React, { useContext, useEffect } from "react";
import "./job_item.css";
import SlickImageSlider from "../custom_image_slider/SlickImageSlider";
import WaContactButton from "../wa_contact_button/WaContactButton";
import { ScrollContext } from "../../util/ScrollContext";

const JobItem = ({ jobs, jobItemRefs, currentIndex }) => {
  const { setSectionRef } = useContext(ScrollContext);

  useEffect(() => {
    jobs.forEach((_, index) => {
      setSectionRef(`job-${currentIndex}-${index}`, jobItemRefs.current[index]);
    });
  }, [jobs, setSectionRef, currentIndex, jobItemRefs]);

  return (
    <div className="jobs_container">
      {jobs.map((job, index) => (
        <div
          className="job_container"
          key={index}
          ref={(el) => (jobItemRefs.current[index] = el)}
        >
          <div className="job_header">
            <div className="job_title_container">
              <h1>{job.title}</h1>
            </div>
            <div className="job_info_container">
            <h2>{job.jobInfo}</h2>
            </div>
            
            <div className="job_header_buttom">
              <div className="icon_container">
                <img src={job.icon} className="job_icon" alt="Job icon" />
              </div>
              <div className="text_container">
                <p>
                  <ul>
                    {job.description.map((d, idx) => (
                      <li key={idx} style={{ direction: "rtl" }}>
                        {d}
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <SlickImageSlider images={job.gallery} />
          <WaContactButton jobDetails={job}/>
        </div>
      ))}
    </div>
  );
};

export default JobItem;
