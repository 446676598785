import React from 'react';
import MapComponent from './MapComponent ';
import { locations } from '../../util/locations';
import { IoLocationSharp } from "react-icons/io5";
import "../contact_section/contactSection.css"

const ContactSection = () => {
  return (
    <div id='contact_section_component' className='contact_section_container'>
            <div id='contact_section_details' className='contact_details_container' style={{width:window.innerWidth<600?"100%":"40%"}}>
        <h1 className='contact_us_title'>צרו איתנו קשר</h1>
        <p>מספר נייד: 054-911-6717
          <br/>
        אימייל: arnoldmat1991@gmail.com
        <br/>
        זמני הפעילות: א'-ה' 08:00 - 20:00, ו' 8:00 - 13:30
        <br/>
        זמינים גם בוואטסאפ בכל שעה לכל מענה!
        </p>
        
        <p>חברתנו פועלת בעיקר באזור הדרום בערים:</p>
        <div id='locations_container' className='locations_names_container'>
        {locations.map((l)=>(<div className='location_name_container'><IoLocationSharp color='#EA4335' />{l}</div>))}
        </div>
      </div>
      <div id='contact_section_map_container' className='contact_map_container' style={{width:window.innerWidth<600?"100%":"50%"}}>
      <MapComponent/>
      </div>

    </div>
  )
}

export default ContactSection