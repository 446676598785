import React, { useContext, useEffect, useRef, useState } from "react";
import "./jobsNavigator.css";
import JobItem from "../job_item/JobItem";
import { ScrollContext } from "../../util/ScrollContext";

function JobsNavigator({ jobsObjects }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const jobItemRefs = useRef([]);

  const { setSectionRef, scrollToSection } = useContext(ScrollContext);

  useEffect(() => {
    jobsObjects[currentIndex].jobs.forEach((_, index) => {
      setSectionRef(`job-${currentIndex}-${index}`, jobItemRefs.current[index]);
    });
  }, [setSectionRef, jobsObjects, currentIndex]);



  return (
    <div className="slider">
      {/* <div className="dots" style={{width:window.innerWidth<600?"auto":"100%"}}>
        {jobsObjects.map((image, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          >
            {image.title}
          </div>
        ))}
      </div> */}
      <div
        className="info_container"
        style={{ gap: window.innerWidth < 600 ? "30px" : "100px" }}
      >
        <img 
          src={jobsObjects[currentIndex].img}
          height="250px"
          style={
            window.innerWidth < 600
              ? { objectFit: "cover" }
              : { objectFit: "contain" }
          }
          alt={`Slide ${currentIndex}`}
        />
        <div  style={{ width: window.innerWidth < 600 ? "100%" : "40%" ,padding:window.innerWidth<600?"none":"10px 100px 80px 100px"}}>
          <span className="info_title">{jobsObjects[currentIndex].subTitle}</span>
          <p className="info_description">{jobsObjects[currentIndex].description}</p>
          <div className="jobs_buttons">
            {jobsObjects[currentIndex].jobs.map((job, index) => (
              <button
                className="job_button_scroll"
                key={index}
                onClick={() => scrollToSection(`job-${currentIndex}-${index}`)}
              >
                {job.title}
              </button>
            ))}
          </div>
        </div>
      </div>

      <JobItem
        jobs={jobsObjects[currentIndex].jobs}
        jobItemRefs={jobItemRefs}
        currentIndex={currentIndex}
      />
    </div>
  );
}

export default JobsNavigator;
