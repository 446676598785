import React from "react";
import imageArnold from "../../assets/images/workerMain.jpg";
import "../business_description/BusinessDescription.css"

const BusinessDescription = () => {
  return (
    <div className="description_container">
      <div>
        <img src={imageArnold} alt="main_top_image_worker_thumb" width="1250px" />
      </div>
      <div className="p_container" style={{width:window.innerWidth<600?"100%":"25%"}}>
        <h1 className="description_title">א.מ פרוייקטים</h1>
        <p>א.מ פרוייקטים הינה חברה הכוללת בעלי מקצוע מנוסים ומיומנים במגוון תחומים ושירותים.
חברתנו חרטה על דגלה- אמינות, מקצוענות, וזמינות על מנת לספק ללקוחותינו את השירות הטוב ביותר לצד תחושת ביטחון ואמון.
אנו מזמינים אתכם לשוחח עם נותני השירות בחברה ולקבל חוות דעת איכותית ומקצועית ללא פשרות בכל תחום שתבחרו.
        </p>
      </div>

    </div>
  );
};

export default BusinessDescription;
