import React, { useContext } from "react";
import logo from "../../assets/logos/logo-mobile.png";
import "../navigationHeader/navigationHeader.css";
import { ScrollContext } from "../../util/ScrollContext";

const NavigationHeaderMobile = () => {
  const { scrollToSection } = useContext(ScrollContext);
  return (
    <header id='mobile_header' className="mobile_header">
      <div>
      <img src={logo} width="80px" alt="Logo" />
      </div>
      <div className="navigation_list_mobile">
            <div onClick={() => scrollToSection("jobsNavigator")}>סוגי עבודות</div>
            <div onClick={() => scrollToSection("businessDescription")}>אודות</div>
            <div onClick={() => scrollToSection("contactForm")}>צרו קשר</div>
          </div>
    </header>
  )
}

export default NavigationHeaderMobile