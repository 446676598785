
import './App.css';
import Home from './pages/Home';
import NavigationHeader from './components/navigationHeader/NavigationHeader';
import { ScrollProvider } from './util/ScrollContext';
import StickyFooter from './components/sticky_footer/StickyFooter';

function App() {
  return (
  
    <div className="App">
      <ScrollProvider>
        <NavigationHeader/>
        <Home/>
      </ScrollProvider>
      <StickyFooter />
      
    </div>
  );
}

export default App;
