import { useState } from "react";
import Select from "../ui/Select";
import { locations } from "../../util/locations";
import { FaWhatsapp } from "react-icons/fa";

function WaContactForm({ jobs }) {
  const [formData, setFormData] = useState({
    name: "",
    locationFrom: "",
    job: "",
    date: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function formSubmitHandle(e) {
    e.preventDefault();
    const isEmptyField = Object.values(formData).some((value) => value === "");

    if (isEmptyField) {
      alert("מלאו בבקשה את כל הפרטים כדי שנוכל לתת מענה בדרך הכי מהירה");
      return;
    }

    const [datePart, timePart] = formData.date.split("T");

    const phoneNumber = "+972549116717";
    const url =
      "https://wa.me/" +
      phoneNumber +
      "?text=" +
      " היי שמי " +
      formData.name +
      " וברצוני לשמוע פרטים ומחירים על " +
      formData.job +
      "%0a" +
      " אשמח לקבוע לתאריך " +
      datePart +
      "%0a" +
      " ביצוע העבודה בעיר " +
      formData.locationFrom +
      "%0a";

    window.open(url, "black").focus();
    console.log(formData);
  }

  return (
    <div>
      <form className="form_container" onSubmit={(e) => formSubmitHandle(e)}>
        <img src={jobs.icon} className="contact_img_icon"/>
        <h3 className="contact_title">מחפשים שירות של {jobs.title}?</h3>
        <h4 className="contact_sub_title">מלאו את הפרטים ושלחו לנו הודעה</h4>
        <label className="label_style" htmlFor="name">
          שם:
        </label>
        <input
          className="input_area"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />

        <Select
          name="locationFrom"
          inputData={locations}
          label="עיר:"
          dataRef={formData.locationFrom}
          handleChange={handleChange}
        />

        <Select
          name="job"
          inputData={jobs.description}
          label="סוג העבודה:"
          dataRef={formData.job}
          handleChange={handleChange}
        />

        <label htmlFor="date">לתאריך:</label>
        <input
          style={{ width: "93%" ,height:"30px"}}
          type="datetime-local"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
        />

        <button className="submit_button_contact"><FaWhatsapp size={30} /> שלח הודעה</button>
      </form>
    </div>
  );
}

export default WaContactForm;
