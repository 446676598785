import React, { createContext, useRef } from 'react';

export const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const sectionRefs = useRef({});

  const setSectionRef = (key, ref) => {
    sectionRefs.current[key] = ref;
  };

  const scrollToSection = (key, offset = -100) => {
    const element = sectionRefs.current[key];
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <ScrollContext.Provider value={{ setSectionRef, scrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};
