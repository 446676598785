export const locations = 
 [
    "אשקלון",
    "אשדוד",
    "אופקים",
    "באר שבע",
    "נתיבות",
    "שדרות",
    "קריית גת",
    "יבנה",
    "רחובות",
    "גן יבנה",
    "ראשון לציון",
  ]