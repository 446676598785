import React, { useRef, useEffect, useContext } from "react";
import JobsNavigator from "../components/jobs_navigator/JobsNavigator";
import { jobsObjects } from "../data/jobsObjects";

import { ScrollContext } from "../util/ScrollContext";
import BusinessDescription from "../components/business_description/BusinessDescription";
import ContactSection from "../components/contact_section/ContactSection";

const HomeContent = () => {
  const { setSectionRef } = useContext(ScrollContext);
  const jobsNavigatorRef = useRef(null);
  const businessDescriptionRef = useRef(null);
  const contactFormRef = useRef(null);

  useEffect(() => {
    setSectionRef("jobsNavigator", jobsNavigatorRef.current);
    setSectionRef("businessDescription", businessDescriptionRef.current);
    setSectionRef("contactForm", contactFormRef.current);
  }, [setSectionRef]);

  return (
    <main id="home-page" style={{overflowX:"hidden",backgroundColor:"#353535"}}>

      <section id="business-description" ref={businessDescriptionRef}>
        <BusinessDescription/>
      </section>
      <section id="image-slider" ref={jobsNavigatorRef}>
        <JobsNavigator jobsObjects={jobsObjects} />
      </section>
      <section id="contact-section" ref={contactFormRef}>
        <ContactSection/>
      </section>
    </main>
  );
};

const Home = () => {
  return (
      <HomeContent />
  );
};

export default Home;
